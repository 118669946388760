<template>
  <el-dialog
      :visible.sync="visible"
      append-to-body
      :show-close="true"
      :center="true"
      :before-close="handleClose"
      title="分享地址"
      width="500px"

  >
    <div>
      <el-form ref="form" :model="formData" label-width="100px">

        <el-form-item label="项目小组" prop="id">
          <el-select
              v-model="formData.group_id"
              clearable
              filterable
              placeholder="请选择项目小组"
              style="width:100%"
              @change="groupSelectChange"
              :rules="[{ required: true, message: '请选择项目小组', trigger: 'blur' }]"

          >
            <el-option
                v-for="item in groupList"
                :key="item.id"
                :label="item.group_name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="formData.remark"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>

import {v2Query} from "@/api/common";

export default {
  components: {},

  data() {
    return {
      visible: false,
      groupList: [],
      groupMemberList: [],
      formData: {
        group_id: "",
        address_ids: "",
        remark: "",
      }
    };
  },


  mounted() {


  },

  methods: {
    groupSelectChange() {

    },
    handleClose() {
      this.$refs["form"].resetFields();

      this.visible = false;

    },


    init(addressIds) {
      // 在这里使用传递的值
      this.formData.address_ids = addressIds;
      this.visible = true;
      this.initGroupList();

    },


    setAddress(data) {
      console.log(data)
      this.formData.lat = data.lat;
      this.formData.lng = data.lng;
      this.formData.adname = data.address;


    },

    initGroupList() {
      var that = this;
      that.queryForm.resetQueryForm()
          .queryFormShareGroup()
          .query().then((response) => {
        that.groupList = response.data.share_group;
      });
    },


    submit() {
      var that = this;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          that.queryForm.resetQueryForm()
              .queryFormShareAddress(that.formData)
              .query().then((response) => {

            if (response.code == 200) {
              this.$message({
                message: "提交成功",
                type: 'success'

              });
              that.$emit('refresh');
              that.visible = false;

            } else {
              this.$message({
                message: response.msg ? response.msg : "提交失败",
              });
            }
          });
        }
      });

    },


  },


};
</script>

<style lang="scss" scoped>


/deep/ .myWindow {
  border-radius: 15px;


  .el-button--primary {
    color: #FFF;
    background-color: #717ebb;
    border-color: #717ebb;
  }


  /*
  .footer{
    display: flex;
    justify-content: center;
  }*/


}
</style>
