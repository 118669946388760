import { render, staticRenderFns } from "./select-point-dialog.vue?vue&type=template&id=88441d28&scoped=true&"
import script from "./select-point-dialog.vue?vue&type=script&lang=js&"
export * from "./select-point-dialog.vue?vue&type=script&lang=js&"
import style0 from "./select-point-dialog.vue?vue&type=style&index=0&id=88441d28&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88441d28",
  null
  
)

export default component.exports