<template>
  <div class="container">
    <el-row style="margin-bottom:20px">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>地址</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="我的地址" name="first" lazy>
      </el-tab-pane>
      <el-tab-pane label="他人地址" name="second" lazy>
      </el-tab-pane>


    </el-tabs>
    <el-form ref="form" :model="searchFormData">
      <el-row type="flex" class="row-bg" justify="space-around">

        <el-col :span="6">
          <el-form-item label="">
            <el-input placeholder="输入名称搜索" v-model="searchFormData.keyword" clearable=""></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-button type="primary" @click="search">搜索</el-button>
        </el-col>
        <el-col :span="6" :offset="6" style="text-align:right">
          <el-button type="primary" @click="share">分享地址</el-button>
        </el-col>
        <el-col :span="6" :offset="6" style="text-align:right">
          <el-button type="primary" @click="add">添加地址</el-button>
        </el-col>
      </el-row>
    </el-form>


    <el-table
        ref="table"
        v-loading="searchLoading"
        :data="pageProps.records"
        element-loading-text="正在查询"
        fit
        stripe
        highlight-current-row
    >

      <el-table-column align="center" label="" width="100" v-if="this.tabIndx != 1">
        <template slot-scope="{row}">
          <el-checkbox :value="row.checked" @change="handleCheckboxChange(row.u_address_id)"></el-checkbox>
        </template>
      </el-table-column>

      <!--<el-table-column align="center" label="项目" prop="project.name"></el-table-column>-->
      <el-table-column align="center" label="店铺名称" prop="shop_name"></el-table-column>
      <el-table-column align="center" label="所在地区" prop="adname"></el-table-column>
      <el-table-column align="center" label="详细地址" prop="address"/>

      <el-table-column v-if="this.tabIndx != 1" label="操作" fixed="right" align="center"
                       class-name="small-padding fixed-width" width="200">
        <template slot-scope="{row}">
          <el-button type="primary" size="mini" @click="edit(row.u_address_id)">
            编辑
          </el-button>
          <el-button type="warning" size="mini" @click="del(row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align:center;padding:20px">
      <!--分页-->
      <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageProps.total"
          :page-size="pageProps.pageSize"
          :page-sizes="[5, 10, 50, 100]"
          :current-page="pageProps.pageNum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
      />
    </div>

    <share-address-dialog ref="shareDialog" @refresh="fetchData"></share-address-dialog>

    <address-add-dialog ref="addressDialog" @refresh="fetchData"></address-add-dialog>

    <address-info-dialog ref="addressInfoDialog" @refresh="fetchData"></address-info-dialog>

  </div>


</template>

<script>
import {v2Query} from "@/api/common";
import shareAddressDialog from "@/views/companyAddress/share-address-dialog.vue";
import addressAddDialog from "@/views/companyAddress/address-add-dialog.vue";
import addressInfoDialog from "@/views/companyAddress/address-info-dialog.vue";

export default {
  name: "Director-Work-Address",
  components: {
    addressAddDialog,
    addressInfoDialog,
    shareAddressDialog
  },
  data() {
    return {
      tabIndx: 0,
      activeName: 'first',
      items: [], // 数据数组
      selectedItems: [], // 存储选中项的数组
      selectAll: false, // 是否全选
      route_event: {
        add_address: true,
        edit_address: true,
        del_address: true,
      },
      searchLoading: false,
      pageProps: {
        records: [],
        total: 0,
        pageSize: 10,
        pageNum: 1
      },

      searchFormData: {
        keyword: "",
        page: 0,
        limit: 0
      }

    }
  },

  mounted() {
    this.fetchData();
  },


  methods: {
    handleClick(tab) {
      this.tabIndx = tab.index;
      this.pageProps.records = [];

      if (tab.index == 1) {
        this.shareLists();
      } else {
        this.fetchData();
      }
    },
    handleCheckboxChange(itemId) {
      if (this.selectedItems.includes(itemId)) {
        // 如果选中项数组中已包含该项，则从数组中移除
        const index = this.selectedItems.indexOf(itemId);
        this.selectedItems.splice(index, 1);
      } else {
        // 如果选中项数组中不包含该项，则添加到数组中
        this.selectedItems.push(itemId);
      }

    },
    add() {
      this.$refs.addressDialog.init();
    },
    share() {
      var that = this;
      if (this.selectedItems.length === 0) {
        that.$message.error("请选择要分享的地址");
        return false;
      }
      this.$refs.shareDialog.init(this.selectedItems);

    },
    edit(u_address_id) {
      this.$refs.addressInfoDialog.init(u_address_id);
    },

    del(row) {
      var that = this;
      this.$confirm('确定删除地址?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        const u_address_id = row.u_address_id;
        that.queryForm.resetQueryForm()
            .queryFormAddressDel(u_address_id)
            .query().then((response) => {
          if (response.code == 200) {
            that.$message.success("删除成功");
            that.fetchData();
          }
        });

      }).catch(() => {

      });
    },

    search() {
      this.fetchData();
    },
    shareLists() {
      var that = this;
      that.searchFormData.page = that.pageProps.pageNum;
      that.searchFormData.list_rows = that.pageProps.pageSize;


      v2Query({
        company_share_list: {
          form: that.searchFormData,
        }
      }).then((response) => {
        if (response.code == 200) {
          var _data = response.data.company_share_list;
          that.pageProps.records = _data.data;
          that.pageProps.total = _data.total;
        } else {
          that.$message.error(response.msg ? response.msg : "请求错误");
        }

      });
    },
    fetchData() {
      var that = this;
      that.searchFormData.page = that.pageProps.pageNum;
      that.searchFormData.limit = that.pageProps.pageSize;

      v2Query({
        company_address_list: {
          form: that.searchFormData,
        }
      }).then((response) => {
        if (response.code == 200) {
          var _data = response.data.company_address_list;
          that.pageProps.records = _data.data;
          that.pageProps.total = _data.total;
          return true;
        }

        that.$message.error(response.msg ? response.msg : "请求错误");
      });
    },

    // 当前页变更
    handleCurrentChange(num) {
      this.pageProps.pageNum = num
      if (this.tabIndx == 1) {
        this.shareLists();

      } else {
        this.fetchData()
      }

    },


    // 每页显示页面数变更
    handleSizeChange(size) {
      this.pageProps.pageSize = size
      this.fetchData()
    },

  }
}
</script>

