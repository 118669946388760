<template>
  <el-dialog
      :visible.sync="visible"
      append-to-body
      :show-close="true"
      :center="true"
      :before-close="handleClose"
      title="编辑地址"
      width="500px"

  >
    <div>
      <el-form ref="form" :model="formData" :rules="rules" label-width="100px">

        <el-form-item label="项目" prop="project_id">
          <el-select
              v-model="formData.project_id"
              clearable
              filterable
              placeholder="请选择项目"
              style="width:100%"
          >
            <el-option
                v-for="item in projectList"
                :key="item.project_id"
                :label="item.name"
                :value="item.project_id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="店铺名称" prop="shop_name">
          <el-input type="text" v-model="formData.shop_name"></el-input>
        </el-form-item>
        <el-form-item label="地址	" prop="adname">
          <el-input type="text" v-model="formData.adname" @focus="selectPoint"></el-input>
        </el-form-item>

        <el-form-item label="详细地址" prop="address">
          <el-input v-model="formData.address"></el-input>
        </el-form-item>


        <el-form-item label="说明">
          <el-input type="textarea" v-model="formData.remark"></el-input>
        </el-form-item>

      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>

    <select-point-dialog ref="mapDialog" @setAddress="setAddress"></select-point-dialog>

  </el-dialog>
</template>

<script>

import {v2Query} from "@/api/common";
import selectPointDialog from "@/components/map/select-point-dialog.vue";

export default {
  components: {selectPointDialog},

  data() {
    return {
      visible: false,
      projectList: [],

      formData: {
        project_id: "",
        shop_name: "",
        adname: "",
        address: "",
        remark: "",
        lat: "",
        lng: ""
      },
      rules: {

        project_id: [
          {required: true, message: '请选择项目', trigger: 'blur'}
        ],
        shop_name: [
          {required: true, message: '请输入店铺名称', trigger: 'blur'}
        ],
        adname: [
          {required: true, message: '请输入名称', trigger: 'blur'}
        ],
        address: [
          {required: true, message: '请输入地址', trigger: 'blur'}
        ],

        /*all_num: [
            {required: true, message: '请输入招聘人数', trigger: 'blur'}
        ],

        sex: [
            {required: true, message: '请选择性别', trigger: 'blur'}
        ]*/
      }
    };
  },


  mounted() {


  },

  methods: {
    handleClose() {
      this.visible = false;
      this.$refs["form"].resetFields();

    },


    init(u_address_id) {
      this.formData.u_address_id = u_address_id;
      this.initData();
      this.initProjectList();
      this.visible = true;
    },

    initData() {
      var that = this;
      that.queryForm.resetQueryForm()
          .queryFormAddressInfo(that.formData.u_address_id)
          .query()
          .then((response) => {

            if (response.code == 200) {
              that.formData = response.data.address_info
            } else {
              this.$message({
                message: response.msg ? response.msg : "获取地址信息失败",
              });
            }
          });
    },

    selectPoint() {
      this.$refs.mapDialog.init();
    },


    setAddress(data) {
      console.log(data)
      this.formData.lat = data.lat;
      this.formData.lng = data.lng;
      this.formData.adname = data.address;


    },

    initProjectList() {
      var that = this;
      that.queryForm.resetQueryForm()
          .queryFormProjectListSelect()
          .query()
          .then((response) => {
            that.projectList = response.data.project_list_select;
          });
    },


    submit() {
      var that = this;
      if (this.formData.lat === '' || this.formData.lng === '') {
        this.$message.error('请先选择地址！');
        return;
      }

      this.$refs["form"].validate((valid) => {
        if (valid) {
          that.queryForm.resetQueryForm()
              .queryFormAddressEdit(that.formData)
              .query()
              .then((response) => {

                if (response.code == 200) {
                  this.$message({
                    message: "提交成功",
                    type: 'success'

                  });
                  that.$emit('refresh');
                  that.visible = false;

                  return true;
                }
                this.$message({
                  message: response.msg ? response.msg : "提交失败",
                });

              });
        }
      });

    },


  },


};
</script>

<style lang="scss" scoped>


/deep/ .myWindow {
  border-radius: 15px;


  .el-button--primary {
    color: #FFF;
    background-color: #717ebb;
    border-color: #717ebb;
  }


  /*
  .footer{
    display: flex;
    justify-content: center;
  }*/


}
</style>
