<template>
  <el-dialog
    :visible.sync="visible"
    append-to-body
    :show-close="true"
    :center="true"
    :before-close="handleClose"
    title="选择地址"
    width="500px"
  >
    <div>
      <iframe width="100%" style="height: 500px; width: 100%; border: none;" :src="map_src"></iframe>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="submitMap">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>

export default {
  data() {
    return {
      visible: false,
       map_src: 'https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=EYFBZ-4BKW6-HYVS7-EEFUP-QRAH5-7LBBX&referer=myapp',
       map_data: {
           // url: 'https://mapapi.qq.com/web/mapComponents/locationPicker/v/index.html?search=1&type=1&key=you key&referer=app name',
            address: '',
            lng: '',
            lat: '',
        }        
    };
  },

 
  mounted() {

  },

created () {
    let that = this
    window.addEventListener('message', function(event) {
      console.log(event)

        // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
        var loc = event.data;
        if (loc && loc.module == 'locationPicker') {//防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
            //{
            //    module:'locationPicker',
            //    latlng: {
            //    lat: 39.998766,
            //        lng: 116.273938
            //    },
            //    poiaddress: "北京市海淀区新建宫门路19号",
            //    poiname: "颐和园",
            //    cityname: "北京市"
            //}
            that.map_data.address = loc.poiaddress
            that.map_data.lat = loc.latlng.lat
            that.map_data.lng = loc.latlng.lng
        }
    }, false);
  },


  
  
  methods: {

    //反向选择，需传入经纬度参数
    show(lat,lng){
      let mat_url = this.map_data.url;
      if(lat && lng){
          mat_url += '&coord=' + lat + ',' + lng;
      }
          this.map_src = mat_url;
          this.mapLocationPicker = true
    },


        //地图选点确定
    submitMap(){
        if(this.map_data.lat === '' || this.map_data.lng === ''){
            this.$message.error('请先选择地址！');
            return;
        }
       
        this.$emit('setAddress',this.map_data);
        this.visible = false;
    },


    handleClose() {
      this.visible = false;

    },


    init() {
      this.visible = true;
    },

    
    
    
  },

 
};
</script>

<style lang="scss" scoped>
/deep/ .myWindow {
    border-radius: 15px;

/deep/ .el-dialog--center .el-dialog__body {
    padding: 5px 25px 5px 25px !important;
}

.el-button--primary {
  color: #FFF;
  background-color: #717ebb;
  border-color: #717ebb;
}


/*
.footer{
  display: flex;
  justify-content: center;
}*/


  }
</style>
